<template>
  <div
    class="w-full pt-8 grid md:grid-cols-2 lg:grid-cols-3 auto-rows-auto gap-7"
  >
    <template v-if="items?.length">
      <SiteBlogCard
        v-for="(item, index) in processedItems"
        :key="index"
        v-bind="item"
        :lazy="index >= 1"
        class="max-md:w-full"
      />
      <div
        v-if="totalPages > 1"
        class="md:col-span-2 lg:col-span-3 flex items-center justify-center mb-8"
      >
        <AppPagination
          static="without-router"
          :model-value="page"
          :total="totalPages"
          :base-url="baseUrl"
          @update:model-value="v => updatePage(v)"
        />
      </div>
    </template>
    <form
      v-else-if="items"
      class="col-span-3 w-full text-center min-h-[20svh] flex flex-col items-center justify-center max-w-[780px] mx-auto"
      @submit.prevent="updateSearch"
    >
      <p class="text-base font-bold mb-2">
        {{ $t('common.noResults') }}
      </p>
      <p
        class="text-sm md:text-base text-neutral-light max-w-[480px] [text-wrap:balance] mb-4"
      >
        {{ $t('blog.searchHint') }}
      </p>
    </form>
    <template v-else>
      <div
        v-for="i in 6"
        :key="i"
        class="group flex flex-col w-[250px] md:w-[352px] md:aspect-[11/10] pb-4 gap-2"
      >
        <div
          class="w-full min-h-[202px] aspect-[16/10] mx-auto rounded-lg bg-gray-lighter animation-pulse"
        ></div>
        <div
          class="w-3/5 h-5 rounded-lg mb-1 mx-auto bg-gray-lighter animation-pulse"
        ></div>
        <div
          class="w-1/2 h-5 rounded-lg mb-3 mx-auto bg-gray-lighter animation-pulse"
        ></div>
        <div
          class="w-4/5 h-3 rounded-lg mx-auto bg-gray-lighter animation-pulse"
        ></div>
        <div
          class="w-3/5 h-3 rounded-lg mx-auto bg-gray-lighter animation-pulse"
        ></div>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { fixImageUrl } from '@/helpers/fixes';

const AppPagination = defineAsyncComponent(
  () => import('@/async-components/App/Pagination.vue')
);
const SiteBlogCard = defineAsyncComponent(
  () => import('@/async-components/Site/Blog/Card.vue')
);

interface Item {
  image?: string;
  imageAlt?: string;
  title: string;
  excerpt: string;
  to: string;
  category?: string;
  categoryLink?: string;
}

const props = defineProps({
  items: {
    type: [Object, Array] as PropType<null | Item[]>
  },
  totalPages: {
    type: Number,
    default: 2
  },
  page: {
    type: Number,
    default: 1
  },
  baseUrl: {
    type: String,
    default: ''
  }
});

const {
  public: { cloudflareImageProcessing: cfUrl }
} = useRuntimeConfig();

const processedItems = props.items?.map((item, index) => {
  const fixedImageUrl = item.image ? fixImageUrl(item.image) : '';
  // const smallImage = fixedImageUrl
  //   ? `${cfUrl}/image?w=35&h=20&f=webp&q=0.1&fit=crop&image=${fixedImageUrl}`
  //   : '';
  const smallImage = '';
  const image = fixedImageUrl
    ? `${cfUrl}/image?w=352&h=202&f=webp&fit=crop&image=${fixedImageUrl}`
    : '';
  const imageSet = fixedImageUrl
    ? `${image} 352w,` +
      `${cfUrl}/image?w=528&h=303&f=webp&fit=crop&image=${fixedImageUrl} 528w` +
      `${cfUrl}/image?w=704&h=404&f=webp&fit=crop&image=${fixedImageUrl} 704w`
    : '';
  const sizes = `(max-width: 559px) calc(100vw - 32px), 352px`;
  return {
    ...item,
    smallImage,
    image,
    imageSet,
    sizes,
    lcp: index === 0
  };
});

type Emits = {
  (e: 'update:page', page: number): void;
  (e: 'update:search', search: string): void;
};

const emit = defineEmits<Emits>();

const search = ref('');

function updatePage(page: number) {
  emit('update:page', page);
}

function updateSearch() {
  emit('update:search', search.value);
}
</script>
